<template>
	<div class="my-scrollbar-content">
		<ul>
			<li class="span-list" v-for="(item,index) in PlayList"  @click.stop="downloadBodang(item,index)">
				<div style="display: flex;align-items: center;">
					<span v-if="CurrentPlayback.name!=item.name" class="pOYdmm9IN1vgZBmXNMdb">{{index+1}}</span>
					<div style="margin-right: .04rem;" v-else>
						<svg-icon  className="download"  icon-class="icon_yy_yy--svgSprite:all"  />
					</div>
					<!--  XYr17inGDJI2d2opY3T0:item.isdownload!=-1 -->
					<span class="Yq318izr1SbfjTmSZsB_" :class="{
						XYr17inGDJI2d2opY3T0:CurrentPlayback.name==item.name
					}">{{item.name}}</span>
					<span class="sdkJDzoSWd1CqOy2h48_">{{item.size}}</span>
				</div>
				<div @click.stop="download(item,index)" v-if="item.isdownload==-1">
					<svg-icon  className="download"  icon-class="comm_icon_xz--svgSprite:all"  />
				</div>
				<div v-else>
					<svg-icon  className="download"  icon-class="comm_icon_gou--svgSprite:all"  />
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
		import { mapState } from 'vuex';
	export default {
		computed:{
			...mapState(['myMusic','songSheetList','CurrentPlayback'])
		},
		props:{
			PlayList:{
				type:[Array,Object,String],
				required:[],
			},
			myMusicList:{
				type:[Array,Object,String],
				required:[],
			},
		},
		data() {
			return {
			}
		},
		created(){
			// this.myMusicList=localStorage.getItem('myMusicList').split(',')
		},
		methods: {
			download(e,index) {
				if(this.myMusicList.indexOf(e.name)==-1){
					this.$emit("onincrease",e,index)
					// e.isdownload=this.myMusicList.length
					// this.myMusicList.push(e.name)
					// localStorage.setItem('myMusicList',this.myMusicList)
				}
			},
			downloadBodang(e,index){
				this.download(e,index)
				this.$emit("xiaAzibaofan",e,index)
			}
		},
	}
</script>

<style scoped>
	.my-scrollbar-content .span-list {
		font-size: .2rem;
		height: .8rem;
		padding: 0;
		    color: var(--theme-text-color-darken);
		border-bottom: thin solid var(--theme-color-line);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.pOYdmm9IN1vgZBmXNMdb {
	    color: var(--theme-text-color-lighten);
	    margin-right: .1rem;
	}
	.Yq318izr1SbfjTmSZsB_ {
	    margin-right: .1rem;
	    max-width: 3.8rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
		display: inline-block;
	    white-space: nowrap;
	}
		.sdkJDzoSWd1CqOy2h48_ {
	    color: var(--theme-text-color-placeholder) !important;
	    font-size: .16rem;
	}
	.XYr17inGDJI2d2opY3T0{
		    color: var(--theme-primary-color) !important;
	}
		
	.span-list div .download{
		    color: var(--theme-primary-color);
			width: .3rem;
			height: .3rem;
	}
</style>
