<template>
	<div class="login-body Details  promote">
		<van-popup v-model="$store.state.isPayment" :close-on-click-overlay="false" z-index="99999" position="bottom">
			<div class="Recharge-box">
				<van-nav-bar :title="$t('Login._Depósito')" left-arrow placeholder @click-left="onClickLeft" />
				<div class="ant-modal-body">
					<div class="FzmhbFIq9UF9HcUNQcR6">
						<div class="BpHxh_ZpgBBhxqSE0kYg">
							<img
								src="./../../assets/icons/images/czsyt_qb_pix.webp"
								alt="." data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
								style="width: 2.25rem; height: 0.8rem;"></div>
						<ul class="iiCi56Rr3LjOlIqMZxQV">
							<li>{{ $t("Login._Abraseuaplicativo") }}</li>
							<li>{{ $t("Login._Estecódigo") }}</li>
							<li class="mzzlO3KQiG9G7KSUfZ1J">{{ $t("Login._Apósopagamento") }}</li>
						</ul>
						<div class="UJS9czukHOApqnAkJ2Pi">
							<div class="d94NmdMyvj7STcDgfZ4t" @click="copyContent('R$'+PaymentInformation.amount)">
								<span>R${{PaymentInformation.amount}}</span>
								<svg-icon  style="width: .3rem;height: .3rem;margin-left:.2rem;color: var(--theme-primary-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
							</div>
							<div class="T787Y1EL6HPSCYVEmM1K">
								<canvas ref="qrcodeCanvas"></canvas>
							</div>
							<div>
								<!-- 
									
								-->
								<div class="XxOQ_xIRgh9ifFurMKwT">{{ $t("Login._Tempoefetivo") }} {{ daoJIshi||'00:00' }} </div>
								<!-- 00:00 -->
								<div class="bR_jjgk5kFwt5n9GwUU4">
									<van-button type="primary"
										@click="copyContent(PaymentInformation.raw)"
									>
										<p style="font-size: .23rem;font-weight: 400;">
											<svg-icon  style="width: .22rem;height: .22rem;margin-left:.2rem;color: var(--theme-primary-font-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
											{{ $t("Login._CopiarcódigoQR") }}</p>
									</van-button>
								</div>
								<p @click="copyContent(PaymentInformation.raw)" class="IkSV8Q6C9l_iCEtW6WDh" > {{ $t("Login._EndereçodocódigoQR") }}  <span
										class="PmwtECGo9wfGBMP0uy71 copyIcon VHOLOpi22NOrZXkHyneM">
										*{{PaymentInformation.raw.split('**')[1]}}
										<svg-icon  style="width: .22rem;height: .22rem;margin-left:.2rem;color: var(--theme-primary-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
										</span></p>
							</div>
						</div>
						<ul class="BEKsCfq0nTlFCi9lua9z">
							<li><span class="jRv1Bt9B_SeMje6TXvrD">{{ $t("Login._Statusdopedido") }}</span>
								<div class="aITAIc_srqZd0REu9rHm"><span class="E1Q8yFHF9f7vKeucmFtP">{{ $t("Login._RegistrosdeAuditoria") }}</span><span class="QjW5LnPzqRze1DtuK0dw">
										<svg-icon  style="width: .22rem;height: .22rem;margin-left:.2rem;color: var(--theme-primary-color);"  icon-class="comm_icon_retry--svgSprite:all"  />
										</span></div>
							</li>
							<li><span class="jRv1Bt9B_SeMje6TXvrD">{{ $t("Login._HoradeCriação") }}</span><span>{{PaymentInformation.create_time}}</span></li>
							<li><span class="jRv1Bt9B_SeMje6TXvrD">{{ $t("Login._NúmerodoPedido") }}</span>
								<div class="aITAIc_srqZd0REu9rHm" @click="copyContent(PaymentInformation.osn)">{{PaymentInformation.osn}}<span
										class="PmwtECGo9wfGBMP0uy71 copyIcon VHOLOpi22NOrZXkHyneM">
										<svg-icon  style="width: .22rem;height: .22rem;margin-left:.2rem;color: var(--theme-primary-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
										</span></div>
							</li>
							<li><span class="jRv1Bt9B_SeMje6TXvrD">{{ $t("Login._Númerodopedidodocomerciante") }}</span>
								<div class="aITAIc_srqZd0REu9rHm" 
								@click="copyContent(PaymentInformation.tradeNo)"
								>{{PaymentInformation.tradeNo}}<span
										class="PmwtECGo9wfGBMP0uy71 copyIcon VHOLOpi22NOrZXkHyneM">
										
										<svg-icon  style="width: .22rem;height: .22rem;margin-left:.2rem;color: var(--theme-primary-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
										</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import QRCode from 'qrcode';
	console.log(QRCode,'QRCodeQRCodeQRCode')
	import { mapState } from 'vuex';
	
	export default {
		computed:{
			...mapState(['popList','PaymentInformation'])
		},
		components: {},
		props: {
			isRecord: {
				type: Boolean,
				default: true,
			},
			payments:{
				type: [Object,Array],
				default: {}
			}
		},
		data() {
			return {
				isFocus: false,
				is_Show: false,
				activeName: '',
				activeLength: 12,
				daoJIshi:'',
			}
		},
		created() {
			// this.generateQRCode();
		},
		mounted() {
			this.$watch('PaymentInformation', (newVal, oldVal) => {
			     this.generateQRCode()
				 this.funC(this.PaymentInformation.create_time)
			});
		   
		},
		methods: {
			funC(tem){
					let chuLI=tem.split(' ');
					let spsn=chuLI[1].split(':');
				// 设置倒计时的总时长（毫秒）
					const countdownDuration = 30 * 60 * 1000; // 30分钟
					// chuLI[1].split(':').join(',')
					//chuLI[1].split(':').join(',')
					const startTime = new Date(2024,7,21,Number(spsn[0]),Number(spsn[1]),Number(spsn[2])); // 开始时间
					console.log(chuLI,spsn,2024,7,21,Number(spsn[0]),Number(spsn[1]),Number(spsn[2]))
					let _this=this;
					// 更新倒计时函数
					function updateCountdown() {
						const currentTime = new Date();
						const timeLeft = countdownDuration - (currentTime - startTime);
						
						if (timeLeft >= 0) {
							// 计算时间
							const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
							const seconds = Math.floor((timeLeft / 1000) % 60);
						
							// 输出到页面或控制台
							_this.daoJIshi=`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
							// 如果时间剩余，则继续倒计时
							if (timeLeft > 0) {
							setTimeout(updateCountdown, 1000);
							}
						}
					}
					// 初始化倒计时
					updateCountdown();
			},
			 generateQRCode() {
			  const canvas = this.$refs.qrcodeCanvas;
			  QRCode.toCanvas(canvas, this.PaymentInformation.raw, error => {
				if (error) console.error(error,'QRCode')
			  })
			},
			isShmeoteng() {
				this.is_Show = !this.is_Show;
			},
			isFun(e) {
				this.isFocus = e;
			},
			onClickLeft() {
				this.$store.commit('publicFun',['isPayment',false])
			}
		}
	}
</script>
<style>
	.login-body {
		font-weight: 400;
	}

	.login-body .van-popup {
		background-color: transparent;
	}

	.login-body .van-tab--active .imageWrap {
		color: var(--theme-primary-color) !important;
	}

	.promote.Details .van-nav-bar {
		border-bottom: none;
	}

	.promote.Details [class*=van-hairline]::after {
		border-bottom: none !important;
	}
</style>
<style scoped>
	.FzmhbFIq9UF9HcUNQcR6 {
	    background-color: var(--theme-main-bg-color);
	    border-radius: .14rem;
	    color: var(--theme-text-color-darken);
	    padding: .3rem .2rem
	}
	.BpHxh_ZpgBBhxqSE0kYg {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    border-bottom: .01rem dotted var(--theme-color-line);
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    padding: .1rem 0 .3rem
	}
	
	.BpHxh_ZpgBBhxqSE0kYg .J9p61qenEMZivirj2lmS {
	    margin-right: .24rem
	}
	
	.BpHxh_ZpgBBhxqSE0kYg .oFTaLAgK4CuC9f0PnjrS {
	    font-size: .32rem
	}
	
	.iiCi56Rr3LjOlIqMZxQV li {
	    line-height: .34rem;
	    margin-bottom: .2rem;
	    padding-left: .25rem;
	    position: relative
	}
	
	.iiCi56Rr3LjOlIqMZxQV li:after {
	    background-color: var(--theme-text-color-darken);
	    border-radius: 50%;
	    content: "";
	    height: .1rem;
	    left: 0;
	    position: absolute;
	    top: .12rem;
	    width: .1rem
	}
	
	.iiCi56Rr3LjOlIqMZxQV .mzzlO3KQiG9G7KSUfZ1J {
	    color: var(--theme-secondary-color-finance);
	    font-weight: 700
	}
	
	.iiCi56Rr3LjOlIqMZxQV {
	    font-size: .22rem;
	    padding-top: .3rem
	}
	.UJS9czukHOApqnAkJ2Pi {
	    text-align: center
	}
	
	.UJS9czukHOApqnAkJ2Pi .d94NmdMyvj7STcDgfZ4t {
	    font-size: .5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.UJS9czukHOApqnAkJ2Pi .d94NmdMyvj7STcDgfZ4t .VHOLOpi22NOrZXkHyneM {
	    font-size: .3rem;
	    margin-left: .2rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .T787Y1EL6HPSCYVEmM1K {
	    height: 2.8rem;
	    margin: 0 auto;
	    position: relative;
	    width: 2.8rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .T787Y1EL6HPSCYVEmM1K canvas {
	    display: block;
	    height: 100% !important;
	    width: 100% !important
	}
	
	.UJS9czukHOApqnAkJ2Pi .T787Y1EL6HPSCYVEmM1K .iArQGLk5_o_imJLkPkxh {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-color: rgba(0,0,0,.5);
	    border-radius: .06rem;
	    display: -ms-flexbox;
	    display: flex;
	    height: 100%;
	    justify-content: center;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 100%
	}
	
	.UJS9czukHOApqnAkJ2Pi .T787Y1EL6HPSCYVEmM1K .iArQGLk5_o_imJLkPkxh .GfG7hMBRF_XF4Mx9gZzr {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-color: var(--theme-secondary-color-success);
	    border-radius: 50%;
	    display: -ms-flexbox;
	    display: flex;
	    height: 1.1rem;
	    justify-content: center;
	    width: 1.1rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .XxOQ_xIRgh9ifFurMKwT {
	    font-size: .22rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 {
	    -ms-flex-pack: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    margin-top: .1rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 .ant-btn-background-ghost.ant-btn-primary {
	    color: var(--theme-primary-color)
	}
	
	.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 .ant-btn {
	    font-size: .24rem;
	    height: .7rem;
	    width: 3.25rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 .ant-btn p {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center
	}
	
	.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 .VSBac2_Itc5KmHhRaPZA,.UJS9czukHOApqnAkJ2Pi .bR_jjgk5kFwt5n9GwUU4 .ant-btn p i {
	    margin-right: .2rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .IkSV8Q6C9l_iCEtW6WDh {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .22rem;
	    justify-content: center;
	    padding-top: .1rem;
	    width: 100%
	}
	
	.UJS9czukHOApqnAkJ2Pi .IkSV8Q6C9l_iCEtW6WDh .VHOLOpi22NOrZXkHyneM {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    margin-left: .2rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bHtByV6XfSeNAzjkpCfs {
	    font-size: .22rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bHtByV6XfSeNAzjkpCfs .iiCi56Rr3LjOlIqMZxQV {
	    color: var(--theme-secondary-color-error);
	    font-size: .4rem;
	    font-weight: 700;
	    padding: .3rem 0 .2rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .bHtByV6XfSeNAzjkpCfs span {
	    color: var(--theme-primary-color)
	}
	
	.UJS9czukHOApqnAkJ2Pi .vAfGprjZJYuklwc8jaUL {
	    font-size: .22rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .vAfGprjZJYuklwc8jaUL .iiCi56Rr3LjOlIqMZxQV {
	    color: var(--theme-secondary-color-success);
	    font-size: .4rem;
	    font-weight: 700;
	    padding: .3rem 0 .2rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .vAfGprjZJYuklwc8jaUL span.fFIZ8ryVQUBdAGZ9Wwws {
	    color: var(--theme-secondary-color-success);
	    margin-right: .1rem
	}
	
	.UJS9czukHOApqnAkJ2Pi .vAfGprjZJYuklwc8jaUL span {
	    color: var(--theme-primary-color)
	}
	.BEKsCfq0nTlFCi9lua9z {
	    font-size: .22rem;
	    margin-top: 1.2rem
	}
	
	.BEKsCfq0nTlFCi9lua9z li {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: space-between;
	    margin-bottom: .2rem
	}
	
	.BEKsCfq0nTlFCi9lua9z .jRv1Bt9B_SeMje6TXvrD {
	    color: var(--theme-text-color-lighten)
	}
	
	.BEKsCfq0nTlFCi9lua9z .QjW5LnPzqRze1DtuK0dw,.BEKsCfq0nTlFCi9lua9z .VHOLOpi22NOrZXkHyneM {
	    color: var(--theme-primary-color);
	    margin-left: .2rem
	}
	
	.BEKsCfq0nTlFCi9lua9z .aITAIc_srqZd0REu9rHm,.BEKsCfq0nTlFCi9lua9z .aITAIc_srqZd0REu9rHm .QjW5LnPzqRze1DtuK0dw,.BEKsCfq0nTlFCi9lua9z .aITAIc_srqZd0REu9rHm .copyIcon {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.BEKsCfq0nTlFCi9lua9z .E1Q8yFHF9f7vKeucmFtP {
	    color: var(--theme-secondary-color-finance)
	}
	
	.BEKsCfq0nTlFCi9lua9z .Uzh61GcN5I603f8ZbU7w {
	    color: var(--theme-alt-neutral-2)
	}
	
	.BEKsCfq0nTlFCi9lua9z .yA61bxrWvqdQPv4EaMvo {
	    color: var(--theme-secondary-color-success)
	}
	.ant-modal-body{
		padding: .2rem;
	}

	.Recharge-box {
		position: relative;
		border-color: var(--theme-color-line);
		height: 100vh;
		margin: 0 auto;
		border: thin solid;
		box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
		background-color: var(--theme-bg-color) !important;
		max-width: calc(100vw - 16px);
		max-height: calc((var(--vh, 1vh)* 100)-.9rem);
		overflow: hidden;
	}

	.kGiTu6vRZ5IXnXeNLy_P {
		padding: .15rem .2rem !important;

	}

	.Ul7BC3PcvbrLSMTYUUMO,
	.tatl0H6xJ7X9PJycDHoS {
		display: flex;
		align-items: center;
	}

	.tatl0H6xJ7X9PJycDHoS {
		justify-content: space-between;
		padding: .05rem 0;
	}

	.NPw6Bv6NZhiG1SSQRsyU,
	.Ul7BC3PcvbrLSMTYUUMO {
		color: var(--theme-text-color-darken);
	}

	.fE8GIN37TrnC9J1cuHKr {
		font-size: .24rem;
	}

	.NPw6Bv6NZhiG1SSQRsyU {
		font-size: .26rem;
		font-weight: 700;
	}

	.twh21p2Olzp5Bb1krfdv {
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
	}

	.Q6NkNwL6l8CDPSdtrO2P {
		display: flex;
		color: var(--theme-secondary-color-error);
		font-size: .2rem;
	}

	.suBX7sD8pIzswzbi5L3h {
		display: flex;
		margin-right: .1rem;
	}
</style>
