import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuexStore from './store/$u.mixin.js'
// let vuexStore = require("@/store/$u.mixin.js");
import './assets/public/public.css';
import './assets/public/antd.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/public/donghua.css';

import './utils/prototype.js'
import './assets/public/vant-modify.css';
import Header from "./components/header/index.vue"
import antSelect from "./components/ant-select/index.vue"
import antSelectItem from "./components/ant-select/xisnhiTanchu.vue"
import antModal from "./components/ant-modal/index.vue"

import Tabbar from "./components/tabbar/index.vue"
import './assets/public/rem.js';
import {copyContent,removeThousandSeparatorAndDecimalPoint} from './assets/public/copyContent.js';
import VueI18n from 'vue-i18n'
import Vant from 'vant';
import {Lazyload} from 'vant';
import 'vant/lib/index.css';
import en from './assets/locales/en.js';
// svanLoading.vue
import svanLoading from "./components/svanLoading.vue"
import pt from './assets/locales/pt.js';
// import date from './assets/public/date.js';
Vue.config.productionTip = false
import './assets/icons' // icon
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Lazyload);
Vue.use(VueI18n);
const i18n = new VueI18n({
	// 默认语言，这里的local属性，对应message中的zh、en属性
	locale: 'pt',
	// 引入语言文件
	messages: {
		// 这里的属性名是任意的，您也可以把zh设置为cn等，只是后续切换语言时
		// 要标识这里的语言属性，如：this.$i18n.locale = zh|en|zh|xxx
		'en': en,
		'pt':pt
	}
})
Vue.prototype._i18n = i18n;
Vue.prototype.openUrl = (e,a)=>{
	if(a){
		router.push({name:e,query:{
			...a
		}})
	}else{
		router.push({name:e})
	}
};
Vue.prototype.copyContent =copyContent;
Vue.prototype._coloImg=require('./assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
Vue.prototype.img_none_sj=require('./assets/icons/images/img_none_sj.webp');
Vue.prototype.img_none_jl=require('./assets/icons/images/img_none_jl.webp');


Vue.prototype.removeThousandSeparatorAndDecimalPoint =removeThousandSeparatorAndDecimalPoint;
Vue.prototype.$initGeetest4 = window.initGeetest4;

Vue.component('Header', Header)
Vue.component('Tabbar', Tabbar)
Vue.component('antModal', antModal)
Vue.component('antSelect', antSelect)
Vue.component('sLoading', svanLoading)
Vue.component('antSelectItem', antSelectItem)


new Vue({
  router,
  store,
  i18n,
  copyContent,
  render: h => h(App)
}).$mount('#app')
