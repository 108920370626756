<template>
	<div ref="selectConter" :class="`v0FZl0YCkb_Z5KOXk68M4 ${className}`">
		<van-dropdown-menu :class="{
			'menu-active':isOPen
		}">
		  <van-dropdown-item v-model="value" @change="change" @close="close" @open="open" :options="options" />
			  <div class="addcinte" @click="open" :style="{transform: `rotate(${isOPen?'-90deg':'90deg'})`}">
				  <van-icon  name="arrow" />
			  </div>
		</van-dropdown-menu>
		<!-- <div style="width: 20px;height: 20px;background-color: red;position: fixed;left: 0;top: 0;"></div> -->
	</div>
</template>

<script>
	export default {
		props: {
			value:{
				type: [Number,String],
				default:()=>{
					return 0
				},
			},
			options:{
				type:[Object,Array],
				default:()=>{
					return []
				}
			},
			className:{
				type: String,
				required: '',
			},
		},
	  data() {
	    return {
			isOPen:false,
	    };
	  },
	  mounted(){
		  console.log(this.$refs.selectConter.offsetLeft)
		  console.log(this.$refs.selectConter.getBoundingClientRect(),'top')
		  // element.getBoundingClientRect()
		  // select-conter
	  },
	  methods:{
		  change(e){
			  this.$emit("change",arguments)
		  },
		  open(){
			 this.isOPen=true; 
		  },
		  close(){
			   this.isOPen=false; 
		  }
	  }
	};
</script>

<style>
	.v0FZl0YCkb_Z5KOXk68M4{
		   max-width: 3.15rem;
		   /* min-width: 1.6rem; */
	}
	.v0FZl0YCkb_Z5KOXk68M4 .menu-active{
		position: relative !important;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .menu-active  .van-dropdown-menu__item {
		border: 0.01rem solid var(--theme-primary-color) !important;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__bar{
		background-color: transparent;
		box-shadow: none;
		height: .5rem;
		
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__bar .van-dropdown-menu__item{
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .5rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .5rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		padding-right: .4rem !important;
		color: var(--theme-text-color-lighten) !important;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__bar .van-dropdown-menu__item .van-dropdown-menu__title{
		font-size: .2rem !important;
		color: var(--theme-text-color-lighten) !important;
		padding:0 !important;
		padding-right: 8px !important;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-ellipsis{
		font-size: .2rem !important;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__title::after{
		
		border-color: transparent transparent var(--theme-text-color-lighten) var(--theme-text-color-lighten);
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-popup,.v0FZl0YCkb_Z5KOXk68M4 .van-cell{
			background-color: transparent;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-popup--top{
		width: auto;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-overlay {
		background-color:transparent;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-item{
		position: absolute;
		min-width: 1rem;
		left: 0;
		top: .6rem !important;
		width: 3rem;
		/* overflow: initial; */
		transition: .3s;
		height: 400px;
		/* overflow: initial; */
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-cell--clickable:active{
		background-color: transparent;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-popup{
		    background-color: var(--theme-main-bg-color) !important;
		    border: .01rem solid var(--theme-color-line) !important;
		    border-radius: .1rem;
		    box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .1);
		    box-sizing: border-box;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-cell{
		    font-size: .24rem;
		    /* line-height: .8rem; */
		    min-height: .8rem;
			/* height: .8rem; */
			display: flex;
			align-items: center;
			line-height: .3rem;
		    padding: 0 .2rem;
			color: var(--theme-text-color-lighten);
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-item__option--active{
		color: var(--theme-primary-color);
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-cell__value{
		display: none;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-cell::after{
		border-bottom:none;
		color: var(--theme-text-color-lighten);
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu{
		position: relative;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-cell__title{
		/* white-space: nowrap; */
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-item__content{
		overflow-x: scroll;
	}
	.arrow-box {
	  border: solid transparent;
	  position: relative;
	  padding: 10px;
	  margin: 20px;
	  background-color: #FFFFFF;
	  border-width: 10px;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__title{
		display: block;
		width: 100%;
	}
	.v0FZl0YCkb_Z5KOXk68M4 .van-dropdown-menu__title::after{
		content: '\e660';
		display: inline-block;
		position: absolute;
		right: .2rem;
		display: none;
		    color: var(--theme-text-color);
	}
	.v0FZl0YCkb_Z5KOXk68M4 .addcinte {
		 position: absolute;
		 color: var(--theme-text-color-lighten) !important;
		 right: .2rem;
		 top: 0;
		 height: .5rem;
		 transition: .3s;
		 font-size: .25rem;
		 display: flex;
		 align-items: center;
		 justify-items: center;
		 transform: rotate(90deg);
		 z-index: 99;
	}
</style>