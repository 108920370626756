<template>
    <header class="ant-layout-header pCKI81C6T6W2e4ZAfsHW uJNjizvqNTc61kuO2wZO">
        <div class="IZ3RIKA0JMiWHOYb9u5f">
            <div class="DMmVnO4MrRvIrs3lfxmS" @click="goBack">
                <svg-icon  className="imageWrap"  icon-class="comm_icon_fh--svgSprite:all"  />
            </div>
            <div class="urdJVIoQ0Du2bRHlCOjg">
                <van-tabs v-model="activeName" 
                    @click="routerFun" 
                    background="var(--theme-main-bg-color)" 
                    :lineWidth="(activeLength*7+'px')"
                    title-active-color="var(--theme-primary-color)"
                    title-inactive-color="var(--theme-text-color-darken)"
                    color="var(--theme-primary-color)">
                    <van-tab  title-style="font-size: 0.24rem;" v-for="(item,index) in tabList" :key="index" :title="$t(item.name)" :name="item.route"></van-tab>
                 </van-tabs>
            </div>
			
        </div>
    </header>
  </template>
   
  <script>
  
  export default {
    name: 'Icon',
    props: {
        activeName:{
            type:[String],
            required:'event',
        },
    },
    data(){
        return {
            show:false,
            activeLength:5,
            tabList:[
                {
                    name:'popup._Eventos',
                    route:"event"
                },
                {
                    name:'popup._VIP',
                    route:"vip"
                },
                {
                    name:'popup._Pendente',
                    route:"canReceive"
                },
                {
                    name:'popup._Juros',
                    route:"yuebao"
                },
                {
                    name:'popup._Histórico',
                    route:"records"
                },
                {
                    name:'popup._FundodePrevidência',
                    route:"rechargeFund"
                }
            ]
        }
    },
    methods: {
      routerFun(e,l){
        this.activeLength=l.length;
        this.$router.push({name:e})
      },
      goBack() {
        this.$router.go(-1);
        }
    }
  }
  </script>
   <style>
.pCKI81C6T6W2e4ZAfsHW .van-tabs__line {
    background-color: var(--theme-primary-color);
    height: auto;
    height: .03rem;
}
.pCKI81C6T6W2e4ZAfsHW .van-tabs--line .van-tabs__wrap {
    border-bottom: thin solid var(--theme-color-line);
    height: 0.9rem;
}
</style>
  <style  scoped>
    
.pCKI81C6T6W2e4ZAfsHW {
    background-color: var(--theme-top-nav-bg);
    /* border-bottom: .01rem solid var(--theme-color-line); */
    height: var(--theme-header-height);
    line-height: normal;
    padding: 0;
    height: .9rem;
}
.IZ3RIKA0JMiWHOYb9u5f {
    background-color: var(--theme-main-bg-color);
    border-bottom: .01rem solid var(--theme-color-line);
    overflow: hidden;
    width: 100%;
    height: .9rem;
    position: fixed;
    z-index: 9999;
}
.IZ3RIKA0JMiWHOYb9u5f  .DMmVnO4MrRvIrs3lfxmS {
    cursor: pointer;
    font-size: .24rem;
    left: 0;
    padding: .33rem .4rem .27rem .2rem;
    position: absolute;
    top: 0;
    z-index: 2;
}
.IZ3RIKA0JMiWHOYb9u5f  .DMmVnO4MrRvIrs3lfxmS  .imageWrap{
    width: .24rem;
    height: .24rem;
    color: var(--theme-text-color);
}
.IZ3RIKA0JMiWHOYb9u5f .urdJVIoQ0Du2bRHlCOjg {
    margin-left: .84rem;
    width: calc(100% - .84rem);
}
.ETlYzQW0ZLPLfrXjv6oO .van-tabs__nav {
    -webkit-overflow-scrolling: touch;
    background-color: var(--theme-main-bg-color);
    display: inline-block;
    overflow-x: auto;
    padding: 0;
    white-space: nowrap;
    width: 100%;
}

  </style>
  