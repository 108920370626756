import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store"
let $uStore = [];
try {
	$uStore = store.state;
} catch (e) {
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta:{
      title: "home",
      index: 1,
	  tabBer:true,
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta:{
      title: "home",
      index: 1,
	  tabBer:true,
    }
  },
  {
    path: '/event',
    name: 'event',
    component: () => import(/* webpackChunkName: "about" */ '../views/event.vue'),
    meta:{
      title: "event",
      index: 2,
      IsHeaderTab:true,
	  tabBer:true,
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import(/* webpackChunkName: "about" */ '../views/vip/vip.vue'),
    meta:{
      title: "vip",
      index: 3,
      IsHeaderTab:true,
	  tabBer:true,
    }
  },
  {
    path: '/canReceive',
    name: 'canReceive',
    component: () => import(/* webpackChunkName: "about" */ '../views/canReceive.vue'),
    meta:{
      title: "canReceive",
      index: 4,
      IsHeaderTab:true,
	  tabBer:true,
    }
  }
  ,
  {
    path: '/yuebao',
    name: 'yuebao',
    component: () => import(/* webpackChunkName: "about" */ '../views/yuebao.vue'),
    meta:{
      title: "yuebao",
      index: 5,
      IsHeaderTab:true,
	  tabBer:true,
    }
  },
  {
    path: '/records',
    name: 'records',
    component: () => import(/* webpackChunkName: "about" */ '../views/records.vue'),
    meta:{
      title: "records",
      index: 6,
      IsHeaderTab:true,
	  tabBer:true,
    }
  }
  ,
  {
    path: '/rechargeFund',
    name: 'rechargeFund',
    component: () => import(/* webpackChunkName: "about" */ '../views/rechargeFund.vue'),
    meta:{
      title: "rechargeFund",
      index: 7,
      IsHeaderTab:true,
	  tabBer:true,
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "about" */ '../views/mine.vue'),
    meta:{
      title: "mine",
      index: 8,
      IsHeaderTab:false,
	  tabBer:true,
	  isLogin:true,
    }
  },
  {
    path: '/promote',
    name: 'promote',
    component: () => import(/* webpackChunkName: "about" */ '../views/promote/promote.vue'),
    meta:{
      title: "promote",
      index: 9,
      IsHeaderTab:false,
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "about" */ '../views/notice/index.vue'),
    meta:{
      title: "notice",
      index: 10
    }
  },
  {
    path: '/RecuperaroSaldo',
    name: 'RecuperaroSaldo',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecuperaroSaldo/index.vue'),
    meta:{
      title: "RecuperaroSaldo",
      index: 11
    }
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "about" */ '../views/report/index.vue'),
    meta:{
      title: "report",
      index: 11
    }
  },
  {
    path: '/security',
    name: 'security',
    component: () => import(/* webpackChunkName: "about" */ '../views/security/index.vue'),
    meta:{
      title: "security",
      index: 12
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "about" */ '../views/setting/index.vue'),
    meta:{
      title: "setting",
      index: 12
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/search/index.vue'),
    meta:{
      title: "search",
      index: 13
    }
  },
  {
    path: '/subgame',
    name: 'subgame',
    component: () => import(/* webpackChunkName: "about" */ '../views/subgame/index.vue'),
    meta:{
      title: "subgame",
      index: 14
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import(/* webpackChunkName: "about" */ '../views/details/index.vue'),
    meta:{
      title: "details",
      index: 15
    }
  },
  {
    path: '/webview',
    name: 'webview',
    component: () => import(/* webpackChunkName: "about" */ '../views/webview/index.vue'),
    meta:{
      title: "webview",
      index: 16
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw/index.vue'),
    meta:{
      title: "withdraw",
      index: 17
    }
  },
  {
    path: '/audit-details',
    name: 'audit-details',
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw/audit-details.vue'),
    meta:{
      title: "audit-details",
      index: 18
    }
  },
  {
    path: '/withdraw-details',
    name: 'withdraw-details',
    component: () => import(/* webpackChunkName: "about" */ '../views/withdraw/withdraw-details.vue'),
    meta:{
      title: "withdraw-details",
      index: 19
    }
  },
  {
    path: '/device-info',
    name: 'device-info',
    component: () => import(/* webpackChunkName: "about" */ '../views/device-info/index.vue'),
    meta:{
      title: "device-info",
      index: 20
    }
  },
  {
    path: '/pickUpRecord',
    name: 'pickUpRecord',
    component: () => import(/* webpackChunkName: "about" */ '../views/pickUpRecord/index.vue'),
    meta:{
      title: "pickUpRecord",
      index: 20
    }
  },
  {
    path: '/LinkdeConviteEvent',
    name: 'LinkdeConviteEvent',
    component: () => import(/* webpackChunkName: "about" */ '../views/promote/LinkdeConviteEvent.vue'),
    meta:{
      title: "LinkdeConviteEvent",
      index: 26
    }
  },
  {
    path: '/securityConter',
    name: 'securityConter',
    component: () => import(/* webpackChunkName: "about" */ '../views/securityConter/index.vue'),
    meta:{
      title: "securityConter",
      index: 21
    }
  },
  {
    path: '/SenhadeLogin',
    name: 'SenhadeLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/securityConter/SenhadeLogin.vue'),
    meta:{
      title: "SenhadeLogin",
      index: 22
    }
  },
  
  
  
  
  // withdraw-details
  
]
//history //hash
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.meta.isLogin && !$uStore.vuex_token_head ){
	  store.commit('isRecord',true);
	  store.commit('publicFun',['isRecordType',1])
	  // console.log(to, from,'to, fromto, from')
	  // next('/'+from.name)
  }else{
	  next()
  }
});

export default router
